<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="state">
                <img src="../../assets/images/serve/gth.png" alt="" />
                正在 <span>完善资料</span>
            </div>

            <el-form style="margin-top: 20px" label-position="right" label-width="250px" :model="form" @submit.native.prevent>
                <el-form-item label="*头像(支持jpg、png格式图片)：">
                    <div class="upload-item">
                        <div>
                            <el-upload :limit="1" :action="action" :on-success="beforeLoad" list-type="picture-card" :on-remove="removeImg">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="*账户名：">
                    {{ info.accountKey }}
                </el-form-item>
                <el-form-item label="*手机号：">
                    {{ info.accountPhone }}
                </el-form-item>
                <el-form-item label="*图形验证码：">
                    <div class="code">
                        <input style="width: 450px" v-model="smaCode" type="text" />
                        <Code ref="ref_validateCode" @change="changeCode" />
                        <div class="text" @click="changeImg">看不清？<span>换张图</span></div>
                    </div>
                </el-form-item>
                <el-form-item label="手机验证码:">
                    <div class="code">
                        <input style="width: 563px" v-model="form.smsCode" type="text" />
                        <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                        <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">
                            {{ countDown }}
                        </button>
                    </div>
                </el-form-item>
                <el-form-item label="账户密码：">
                    {{ info.accountPsd }}
                </el-form-item>
                <el-form-item label="*用户姓名：">
                    <div class="code">
                        <input style="width: 563px" v-model="form.userName" type="text" />
                    </div>
                </el-form-item>
                <el-form-item label="*用户身份证号码：">
                    <div class="code">
                        <input style="width: 563px" v-model="form.idNumber" type="text" />
                    </div>
                </el-form-item>
                <el-form-item label="*所属地区：">
                    <div class="code">
                        <el-select v-model="form.province" placeholder="地区">
                            <el-option v-for="item in proList" :key="item.id" :label="item.province" :value="item.provinceid"></el-option>
                        </el-select>
                        <div style="margin: 0 20px">省</div>
                        <el-select v-if="form.province != ''" v-model="form.city" placeholder="地区">
                            <el-option v-for="item in cityList" :key="item.id" :label="item.city" :value="item.cityid"></el-option>
                        </el-select>
                        <div v-if="form.province != ''" style="margin: 0 20px">市</div>
                    </div>
                </el-form-item>
                <el-form-item label="*所属行业：">
                    <div class="code">
                        <el-select v-model="form.industry" placeholder="行业">
                            <el-option v-for="item in industryList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="手上传姓名对应的身份证照片(支持jpg、png格式图片)：">
                    <div class="upload">
                        <div>
                            <el-upload :limit="1" :action="action" :on-success="beforeLoadZ" list-type="picture-card" :on-remove="removeImgZ">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <div class="uload-name">身份证国徽面</div>
                        </div>
                        <div>
                            <el-upload :limit="1" :action="action" :on-success="beforeLoadF" list-type="picture-card" :on-remove="removeImgF">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <div class="uload-name">身份证人像面</div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="*平台交易密码：">
                    <div class="code">
                        <!--  //火狐浏览器 禁止滚轮滚动修改数据  @mousewheel.native.prevent  //谷歌浏览器 禁止滚轮滚动修改数据-->
                        <input style="width: 563px" placeholder="6位数字" v-model="form.payPsd" type="text" />
                    </div>
                </el-form-item>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <el-button class="f-btn" @click="compare">确认</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import Code from "../code.vue";
export default {
    components: { Code },
    props: {
        dialogVisible: Boolean,
        info: Object,
    },
    data() {
        return {
            form: {
                headPic: "", //头像
                smsCode: "", //短信验证码
                userName: "", //姓名
                idNumber: "", //身份证号
                province: "", //省
                city: "", //市
                industry: "", //职业
                idCardImgA: "", //身份证正面
                idCardImgB: "", //身份证反面
                payPsd: "", //支付密码
            },
            smaCode: "",
            action: this.$util.upload,
            industryList: [
                { id: "A", title: "农、林、牧、渔业" },
                { id: "B", title: "采矿业," },
                { id: "C", title: "制造业" },
                { id: "D", title: "电力、热力、燃气及水生产和供应业" },
                { id: "E", title: "建筑业" },
                { id: "F", title: "批发和零售业" },
                { id: "G", title: "交通运输、仓储和邮政业" },
                { id: "H", title: "住宿和餐饮业" },
                { id: "I", title: "信息传输、软件和信息技术服务业" },
                { id: "J", title: "金融业" },
                { id: "K", title: "房地产业" },
                { id: "L", title: "租赁和商务服务业" },
                { id: "M", title: "科学研究和技术服务业" },
                { id: "N", title: "水利、环境和公共设施管理业" },
                { id: "O", title: "居民服务、修理和其他服务业" },
                { id: "P", title: "教育" },
                { id: "Q", title: "卫生和社会工作" },
                { id: "R", title: "文化、体育和娱乐业" },
                { id: "S", title: "公共管理、社会保障和社会组织" },
                { id: "T", title: "国际组织" },
            ], //行业
            // 倒计时
            countDownShow: false,
            count: 60,
            countDown: "",
            isdisabledFn: false,
            proList: [], //省
            cityList: [], //市
        };
    },

    mounted() {
        this.getPro();
    },
    watch: {
        "form.province"(val) {
            if (val != "") {
                this.getCity();
            }
        },
        "form.payPsd"(val) {
            this.form.payPsd = this.form.payPsd.replace(/[^\d]/g, '');
        }
    },
    methods: {
        // 获取省
        getPro() {
            this.$util.post("/address/provinces").then((res) => {
                this.proList = res.data;
            });
        },
        getCity() {
            this.form.city = "";
            this.$util
                .post("/address/city", {
                    fid: this.form.province,
                })
                .then((res) => {
                    this.cityList = res.data;
                });
        },
        handleClose(done) {
            this.$emit("close");
        },
        // 上传
        beforeLoad(response, file, fileList) {
            console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form.headPic = imgs.join(",");
        },
        removeImg(file, fileList) {
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form.headPic = imgs.join(",");
        },
        // 正面身份证
        beforeLoadZ(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form.idCardImgA = imgs.join(",");
        },
        removeImgZ(file, fileList) {
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form.idCardImgA = imgs.join(",");
        },
        // 反面身份证
        beforeLoadF(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form.idCardImgB = imgs.join(",");
        },
        removeImgF(file, fileList) {
            console.log(fileList);
            let imgs = [];
            imgs = fileList.map((item) => {
                return item.response.data.url;
            });
            this.form.idCardImgB = imgs.join(",");
        },
        // 验证码
        getCode() {
            // const email = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            this.$util
                .post("sys-sms/sms-send", {
                    phone: this.info.accountPhone,
                    action: "Other",
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.countDownShow = true;
                        this.settime();
                    }
                });
        },

        settime() {
            if (this.count == 0) {
                this.count = 60;
                this.countDownShow = false;
                return false;
            } else {
                this.countDownShow = true;
                this.isdisabledFn = true;
                this.countDown = "重新发送" + "(" + this.count + ")";
                this.count--;
            }
            setTimeout(() => {
                this.settime();
            }, 1000);
        },
        changeCode(value) {
            this.checkCode = value;
        },
        // 验证码验证
        compare() {
            if (this.form.headPic == "") {
                return this.$message.error("请上传头像");
            }
            if (this.form.smsCode == "") {
                return this.$message.error("请输入短信验证码");
            }
            if (this.form.userName == "") {
                return this.$message.error("请输入姓名");
            }
            if (this.form.idNumber == "") {//industry
                return this.$message.error("请输入身份证号");
            }
            if (this.form.industry == "") {
                return this.$message.error("请选择职业");
            }
            if (this.form.idCardImgA == "") {
                return this.$message.error("请上传身份证正面");
            }
            if (this.form.idCardImgB == "") {
                return this.$message.error("请上传身份证反面");
            }
            if (this.form.payPsd == "") {
                return this.$message.error("请输入交易密码");
            }
            if (this.form.province == "" || this.form.city == "") {
                return this.$message.error("请选择所在地区");
            }
            if (this.smaCode.toUpperCase() === this.checkCode) {
                this.$util.post("/userCenter/submit/info/personal", this.form).then((res) => {
                    if (res.code == 200) {
                        this.$emit("submit");
                    } else {
                        this.$message.error(res.message);
                    }
                });
            } else {
                this.$message.error("请输入正确的图形码");
                this.smaCode = "";
                this.$refs["ref_validateCode"].draw();
            }
        },
        changeImg() {
            this.$refs["ref_validateCode"].draw();
        },
    },
};
</script>

<style lang="scss" scoped>
.state {
    padding: 20px 0 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
.upload {
    display: flex;
    align-items: center;
    .uload-name {
        font-size: 14px;
        color: #2ea7e0;
        margin-left: 57px;
    }
    /deep/.el-upload--picture-card {
        width: 198px;
        height: 124px;
        background: #fff;
        line-height: 134px;
        margin-right: 20px;
    }
    /deep/.el-upload-list--picture-card .el-upload-list__item {
        width: 198px;
        height: 124px;
    }
}
.upload-item {
    display: flex;
    align-items: center;
    /deep/.el-upload--picture-card {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        background: #fff;
        line-height: 110px;
    }
    /deep/.el-upload-list--picture-card .el-upload-list__item {
        width: 100px;
        height: 100px;
        border-radius: 100%;
    }
}
.code {
    height: 48px;
    // border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    // width: 500px;
    input {
        height: 100%;
        // border: none;
        outline: none;
        padding: 0 15px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #eeeeee;
        margin-right: 10px;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.verification {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .text {
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin-left: 10px;
        span {
            color: #e40012;
        }
    }
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    font-size: 16px;
    cursor: pointer;
    color: #e40012;
    .f-btn {
        padding: 15px 180px;
        background: #e40012;
        color: #fff;
        font-size: 18px;
        margin-left: 40px;
    }
}

.avatar-item {
    margin-left: 25px;
    width: 100px;
    height: 100px;
    // background: red;
    border-radius: 100%;
    img {
        width: 100%;
        height: 100%;
    }
}
.text {
    font-size: 14px;
    color: #666666;
    cursor: pointer;
    margin-left: 10px;
    span {
        color: #e40012;
    }
}
</style>
