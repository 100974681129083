<template>
    <div>
        <!-- 个人用户 -->
        <div class="main-box" v-if="info.accountType == 1">
            <div class="main">
                <div class="main-title">
                    基础资料
                    <el-button class="t-btn" v-if="!payPsd" @click="dialogVisible = true">完善资料</el-button>
                </div>
                <div class="cell">
                    <div class="lable">头像：</div>
                    <div class="conter" v-show="info.headPic">
                        <img :src="$util.host + info.headPic" alt="" />
                    </div>
                </div>
                <div class="cell">
                    <div class="lable">账户名：</div>
                    <div class="conter">
                        {{ info.accountKey }}
                    </div>
                </div>
                <div class="cell">
                    <div class="lable">手机号:</div>
                    <div class="conter">
                        {{ info.accountPhone }}
                    </div>
                </div>
                <div class="cell">
                    <div class="lable">账户密码：</div>
                    <div class="conter">
                        <input :type="inpuType ? 'text' : 'password'" :value="info.accountPsd" readonly />
                        <img src="../../assets/images/manage/noeyes.png" @click="inpuType = false" class="eyes" alt="" />
                        <img src="../../assets/images/manage/eyes.png" @click="inpuType = true" class="eyes" alt="" />
                    </div>
                </div>
                <div class="heng"></div>
                <div class="cell">
                    <div class="lable">用户姓名：</div>
                    <div class="conter" v-text="info.realName ? info.realName : '未填写'"></div>
                </div>
                <div class="cell">
                    <div class="lable">用户身份证号码：</div>
                    <div class="conter" v-text="realityInfo.idNumber ? realityInfo.idNumber : '未填写'"></div>
                </div>
                <div class="cell">
                    <div class="lable">所属地区：</div>
                    <div class="conter" v-text="
              info.province
                ? `${allAddressList[info.province]}-${allAddressList[info.city]}`
                : '未填写'
            "></div>
                </div>
                <div class="cell">
                    <div class="lable">所属行业：</div>
                    <div class="conter" v-text="info.industry ? info.industry : '未填写'"></div>
                </div>
                <div class="cell">
                    <div class="lable">上传姓名对应的身份证照片：</div>
                    <div class="card" v-if="realityInfo.cardImgA">
                        <div>
                            <img :src="$util.host + realityInfo.cardImgA" alt="" />
                            身份证国徽面
                        </div>
                        <div>
                            <img :src="$util.host + realityInfo.cardImgB" alt="" />
                            身份证人像面
                        </div>
                    </div>
                    <div v-else>未上传</div>
                </div>
                <div class="cell">
                    <div class="lable">交易密码：</div>
                    <div v-if="info.payPsd != ''" class="conter">
                        <input :type="inpuTypePay ? 'text' : 'password'" :value="info.payPsd" readonly />
                        <img src="../../assets/images/manage/noeyes.png" @click="inpuTypePay = false" class="eyes" alt="" />
                        <img src="../../assets/images/manage/eyes.png" @click="inpuTypePay = true" class="eyes" alt="" />
                    </div>
                    <div class="conter" v-if="info.payPsd == ''">未填写</div>
                </div>
            </div>
            <div class="jiange"></div>
            <div class="foot-main" v-if="payPsd">
                <div class="title">更新资料</div>
                <div class="cell">
                    <div>系统登录资料</div>
                    <span @click="infoShow = true">修改</span>
                </div>
                <div class="cell">
                    <div>账号登录密码</div>
                    <span @click="passwordShow = true">修改</span>
                </div>
                <div class="cell">
                    <div>实名认证资料</div>
                    <span @click="realNameShow = true">修改</span>
                </div>
                <div class="cell">
                    <div>交易密码</div>
                    <span @click="payPassShow = true">修改</span>
                </div>
            </div>
        </div>

        <!-- 企业用户 -->
        <div class="main-box" v-else>
            <div class="main">
                <div class="main-title">
                    基础资料
                    <el-button class="t-btn" @click="qyDialogVisible = true">完善资料</el-button>
                </div>
                <div class="cell">
                    <div class="lable">头像：</div>
                    <div class="conter" v-show="info.headPic">
                        <img :src="$util.host + info.headPic" alt="" />
                    </div>
                </div>
                <div class="cell">
                    <div class="lable">账户名：</div>
                    <div class="conter">
                        {{ info.accountKey }}
                    </div>
                </div>
                <div class="cell">
                    <div class="lable">账户密码：</div>
                    <div class="conter">
                        <input :type="inpuType ? 'text' : 'password'" :value="info.accountPsd" readonly />
                        <img src="../../assets/images/manage/noeyes.png" @click="inpuType = false" class="eyes" alt="" />
                        <img src="../../assets/images/manage/eyes.png" @click="inpuType = true" class="eyes" alt="" />
                    </div>
                </div>
                <div class="heng"></div>
                <div class="cell">
                    <div class="lable">公司名称：</div>
                    <div class="conter" v-text="realityInfo.companyName ? realityInfo.companyName : '未填写'"></div>
                </div>
                <div class="cell">
                    <div class="lable">统一社会信用代码：</div>
                    <div class="conter" v-text="realityInfo.creditCode ? realityInfo.creditCode : '未填写'"></div>
                </div>
                <div class="cell">
                    <div class="lable">法定代表人姓名：</div>
                    <div class="conter" v-text="info.realName ? info.realName : '未填写'"></div>
                </div>
                <div class="cell">
                    <div class="lable">所属地区：</div>
                    <div class="conter" v-text="
              info.province
                ? `${allAddressList[info.province]}-${allAddressList[info.city]}`
                : '未填写'
            "></div>
                </div>
                <div class="cell">
                    <div class="lable">所属行业：</div>
                    <div class="conter" v-text="info.industry ? info.industry : '未填写'"></div>
                </div>
                <div class="cell">
                    <div class="lable">企业对应的营业执照证明：</div>
                    <div class="card2" v-if="realityInfo.businessLicense">
                        <div>
                            <img :src="$util.host + realityInfo.businessLicense" alt="" />
                            营业执照照片
                        </div>
                    </div>
                    <div v-else>未上传</div>
                </div>
            </div>
            <div class="jiange"></div>
            <div class="foot-main" v-if="payPsd">
                <div class="title">更新资料</div>
                <div class="cell">
                    <div>系统登录资料</div>
                    <span @click="qyInfoShow = true">修改</span>
                </div>
                <div class="cell">
                    <div>账号登录密码</div>
                    <span @click="qyPasswordShow = true">修改</span>
                </div>
                <div class="cell">
                    <div>实名认证资料</div>
                    <span @click="qyRealNameShow = true">修改</span>
                </div>
                <div class="cell">
                    <div>交易密码</div>
                    <span @click="qyPayPassShow = true">修改</span>
                </div>
            </div>
        </div>
        <!-- 个人完善资料 -->
        <PersonMaterial v-if="dialogVisible" :dialogVisible="dialogVisible" :info="info" :realityInfo="realityInfo" @submit="proSubmit" @close="close" />
        <!-- 个人登录资料 -->
        <div v-if="infoShow | passwordShow | realNameShow | payPassShow">
            <PersonInfo :infoShow="infoShow" :info="info" :realNameShow="realNameShow" @submit="proSubmit" :passwordShow="passwordShow" :payPassShow="payPassShow" @close="close" />
        </div>
        <!-- 企业完善资料 -->
        <EnterMaterial v-if="qyDialogVisible" :qyDialogVisible="qyDialogVisible" @submit="proSubmit" :info="info" @close="close" />
        <!-- 企业登录资料 -->
        <div v-if="qyInfoShow | qyPasswordShow | qyRealNameShow | qyPayPassShow">
            <EnterInfo :qyInfoShow="qyInfoShow" :info="info" :realityInfo="realityInfo" :qyPasswordShow="qyPasswordShow" @submit="proSubmit" :qyRealNameShow="qyRealNameShow" :qyPayPassShow="qyPayPassShow" @close="close" />
        </div>
    </div>
</template>

<script>
import EnterInfo from "../../components/manage/enterInfo.vue";
import EnterMaterial from "../../components/manage/enterMaterial.vue";
import PersonInfo from "../../components/manage/personInfo.vue";
import PersonMaterial from "../../components/manage/personMaterial.vue";
export default {
    components: { PersonMaterial, PersonInfo, EnterMaterial, EnterInfo },

    data() {
        return {
            inpuTypePay: false,
            // 个人
            dialogVisible: false, //完善资料弹出层
            infoShow: false, // 修改登录信息弹出层
            passwordShow: false, //修改密码弹出层
            realNameShow: false, //实名认证修改
            payPassShow: false, //交易密码修改
            // 企业
            qyDialogVisible: false, //完善资料弹出层
            qyInfoShow: false, // 修改登录信息弹出层
            qyPasswordShow: false, //修改密码弹出层
            qyRealNameShow: false, //实名认证修改
            qyPayPassShow: false, //交易密码修改
            // input: '3213254',
            inpuType: false, //密码的显示隐藏
            info: {}, //我的个人信息
            realityInfo: {}, //公司的信息  企业
            allAddressList: {}, //所有地址
            industryList: [
                { id: "A", title: "农、林、牧、渔业" },
                { id: "B", title: "采矿业," },
                { id: "C", title: "制造业" },
                { id: "D", title: "电力、热力、燃气及水生产和供应业" },
                { id: "E", title: "建筑业" },
                { id: "F", title: "批发和零售业" },
                { id: "G", title: "交通运输、仓储和邮政业" },
                { id: "H", title: "住宿和餐饮业" },
                { id: "I", title: "信息传输、软件和信息技术服务业" },
                { id: "J", title: "金融业" },
                { id: "K", title: "房地产业" },
                { id: "L", title: "租赁和商务服务业" },
                { id: "M", title: "科学研究和技术服务业" },
                { id: "N", title: "水利、环境和公共设施管理业" },
                { id: "O", title: "居民服务、修理和其他服务业" },
                { id: "P", title: "教育" },
                { id: "Q", title: "卫生和社会工作" },
                { id: "R", title: "文化、体育和娱乐业" },
                { id: "S", title: "公共管理、社会保障和社会组织" },
                { id: "T", title: "国际组织" },
            ], //行业
            payPsd: false, //是否存在交易密码
        };
    },

    mounted() {
        this.getInfo();
        this.allData();
        this.allAddress();
    },

    methods: {
        // 获取个人信息

        getInfo() {
            this.$util.post("/user/info").then((res) => {
                this.industryList.forEach((item) => {
                    if (res.data.industry == item.id) {
                        res.data.industry = item.title;
                    }
                });
                this.info = res.data;
                if (res.data.hasPayPsd) {
                    this.payPsd = true;
                } else {
                    this.payPsd = false;
                }
            });
        },
        // 获取所有的数据
        allData() {
            this.$util.post("/userCenter/examine/info").then((res) => {
                if (res.data) {
                    this.realityInfo = res.data;
                }
            });
        },
        // 获取所有的地址
        allAddress() {
            this.$util.post("/address/allMap").then((res) => {
                console.log(res);
                this.allAddressList = res.data.all;
            });
        },
        // 所有弹框确认修改时触发
        proSubmit() {
            this.$router.go(0);
            this.dialogVisible = false;
            this.infoShow = false;
            this.passwordShow = false;
            this.realNameShow = false;
            this.payPassShow = false;
            // 企业
            this.qyDialogVisible = false;
            this.qyInfoShow = false;
            this.qyPasswordShow = false;
            this.qyRealNameShow = false;
            this.qyPayPassShow = false;
            this.getInfo();
            this.allData();
        },
        // 所有弹框关闭时触发
        close() {
            this.dialogVisible = false;
            this.infoShow = false;
            this.passwordShow = false;
            this.realNameShow = false;
            this.payPassShow = false;
            // 企业
            this.qyDialogVisible = false;
            this.qyInfoShow = false;
            this.qyPasswordShow = false;
            this.qyRealNameShow = false;
            this.qyPayPassShow = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.main-box {
    background: #f6f6f6;
    padding: 20px 0 90px;
}
.main {
    width: 1140px;
    margin: 0 auto;
    padding: 30px;
    background: #fff;
    .main-title {
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;
        color: #222222;
        margin-bottom: 20px;
    }
    .t-btn {
        padding: 13px 54px;
        background: #e40012;
        border: none;
        outline: none;
        color: #fff;
    }
}
.cell {
    display: flex;
    margin-bottom: 30px;
    color: #999999;
    align-items: center;
    font-size: 16px;
    .lable {
        margin-right: 20px;
        min-width: 218px;
        text-align: right;
    }
    .conter {
        display: flex;
        align-items: center;
        color: #222222;
        img {
            width: 100px;
            height: 100px;
            border-radius: 100%;
        }
        input {
            border: none;
            width: 80px;
            outline: none;
        }
        .eyes {
            width: 19px;
            height: 13px;
            cursor: pointer;
            margin-right: 10px;
        }
    }
}
.heng {
    height: 2px;
    background: #eeeeee;
    margin-bottom: 50px;
}
.jiange {
    height: 4px;
    background: #f6f6f6;
}
.foot-main {
    width: 1140px;
    margin: 0 auto;
    padding: 30px;
    background: #fff;
    .title {
        font-size: 24px;
        color: #222222;
    }
    .cell {
        display: flex;
        align-items: center;
        padding: 30px 0;
        justify-content: space-between;
        border-bottom: 1px solid #eeeeee;
        font-size: 18px;
        color: #222;
        span {
            cursor: pointer;
            color: #2ea7e0;
        }
    }
}
.card {
    display: flex;
    align-items: center;
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #2ea7e0;
        font-size: 14px;
        margin-right: 20px;
        img {
            width: 185px;
            height: 116px;
            margin-bottom: 14px;
        }
    }
}
.card2 {
    display: flex;
    align-items: center;
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #2ea7e0;
        font-size: 14px;
        margin-right: 20px;
        img {
            width: 112px;
            height: 150px;
            margin-bottom: 14px;
        }
    }
}
</style>
