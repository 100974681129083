<template>
    <div>
        <el-dialog :visible.sync="qyInfoShow" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="state">
                <img src="../../assets/images/serve/gth.png" alt="">
                正在 <span>系统登录资料</span>
            </div>

            <el-form style="margin-top:20px" label-position="right" label-width="250px" :model="form1" @submit.native.prevent>
                <el-form-item label="*头像(支持jpg、png格式图片)：">
                    <div class="upload-item">
                        <div>
                            <el-upload :limit="1" :action="action" :file-list="fileList" :on-success="beforeLoad" list-type="picture-card" :on-remove="removeImg">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="*账户名：">
                    <div class="code">
                        <input style="width:450px" v-model="form1.accountKey" type="text">
                    </div>
                </el-form-item>
                <el-form-item label="*手机号：">
                    {{info.accountPhone}}
                </el-form-item>
                <el-form-item label="*图形验证码：">
                    <div class="code">
                        <input style="width:450px" v-model="smaCode" type="text">
                        <Code ref="ref_validateCode1" @change="changeCode" />
                        <div class="text" @click="changeImg(1)">看不清？<span>换张图</span></div>
                    </div>
                </el-form-item>
                <el-form-item label="手机验证码:">
                    <div class="code">
                        <input style="width:563px" v-model="form1.smsCode" type="text">
                        <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                        <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">{{countDown}}</button>
                    </div>
                </el-form-item>

                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <el-button class="f-btn" @click="compare(1)">确认</el-button>
                </div>
            </el-form>
        </el-dialog>
        <!-- 登录密码 -->
        <el-dialog :visible.sync="qyPasswordShow" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="state">
                <img src="../../assets/images/serve/gth.png" alt="">
                正在 <span>修改账户登录密码</span>
            </div>

            <el-form style="margin-top:20px" label-position="right" label-width="250px" :model="form2" @submit.native.prevent>
                <el-form-item label="*手机号：">
                    {{info.accountPhone}}
                </el-form-item>
                <el-form-item label="*图形验证码：">
                    <div class="code">
                        <input style="width:450px" v-model="smaCode" type="text">
                        <Code ref="ref_validateCode2" @change="changeCode" />
                        <div class="text" @click="changeImg(2)">看不清？<span>换张图</span></div>
                    </div>
                </el-form-item>
                <el-form-item label="手机验证码:">
                    <div class="code">
                        <input style="width:563px" v-model="form2.smsCode" type="text">
                        <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                        <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">{{countDown}}</button>
                    </div>
                </el-form-item>
                <!-- <el-form-item label="*旧密码：">
                    <div class="code">
                        <input style="width:450px" v-model="form.phone" type="text">
                    </div>
                </el-form-item> -->
                <el-form-item label="*新密码：">
                    <div class="code">
                        <input style="width:450px" v-model="form2.accountPsd" type="text">
                    </div>
                </el-form-item>
                <el-form-item label="*密码确认：">
                    <div class="code">
                        <input style="width:450px" v-model="form2.accountPsdAgain" type="text">
                    </div>
                </el-form-item>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <el-button class="f-btn" @click="compare(2)">确认</el-button>
                </div>
            </el-form>
        </el-dialog>
        <!-- 实名认证修改 -->
        <el-dialog :visible.sync="qyRealNameShow" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="state">
                <img src="../../assets/images/serve/gth.png" alt="">
                正在 <span>修改实名认证资料</span>
            </div>

            <el-form style="margin-top:20px" label-position="right" label-width="250px" :model="form3" @submit.native.prevent>
                <el-form-item label="*手机号：">
                    {{info.accountPhone}}
                </el-form-item>
                <el-form-item label="*图形验证码：">
                    <div class="code">
                        <input style="width:450px" v-model="smaCode" type="text">
                        <Code ref="ref_validateCode3" @change="changeCode" />
                        <div class="text" @click="changeImg(3)">看不清？<span>换张图</span></div>
                    </div>
                </el-form-item>
                <el-form-item label="手机验证码:">
                    <div class="code">
                        <input style="width:563px" v-model="form3.smsCode" type="text">
                        <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                        <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">{{countDown}}</button>
                    </div>
                </el-form-item>
                <el-form-item label="公司名称：">
                    {{realityInfo.companyName}}
                </el-form-item>
                <el-form-item label="统一社会信用代码：">
                    {{realityInfo.creditCode}}
                </el-form-item>
                <el-form-item label="法定代表人姓名：">
                    {{realityInfo.name}}
                </el-form-item>
                <el-form-item label="*所属地区：">
                    <div class="code">
                        <el-select v-model="form3.province" placeholder="地区">
                            <el-option v-for="item in proList" :key="item.id" :label="item.province" :value="item.provinceid"></el-option>
                        </el-select>
                        <div style="margin:0 20px">省</div>
                        <el-select v-if="form3.province != ''" v-model="form3.city" placeholder="地区">
                            <el-option v-for="item in cityList" :key="item.id" :label="item.city" :value="item.cityid"></el-option>
                        </el-select>
                        <div v-if="form3.province != ''" style="margin:0 20px">市</div>
                    </div>
                </el-form-item>
                <el-form-item label="*所属行业：:">
                    <div class="code">
                        <el-select v-model="form3.industry" placeholder="行业">
                            <el-option v-for="item in industryList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <el-button class="f-btn" @click="compare(3)">确认</el-button>
                </div>
            </el-form>
        </el-dialog>

        <!-- 交易密码 -->
        <el-dialog :visible.sync="qyPayPassShow" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="state">
                <img src="../../assets/images/serve/gth.png" alt="">
                正在 <span>修改交易密码</span>
            </div>

            <el-form style="margin-top:20px" label-position="right" label-width="250px" :model="form4" @submit.native.prevent>
                <el-form-item label="*手机号：">
                    {{info.accountPhone}}
                </el-form-item>
                <el-form-item label="*图形验证码：">
                    <div class="code">
                        <input style="width:450px" v-model="smaCode" type="text">
                        <Code ref="ref_validateCode4" @change="changeCode" />
                        <div class="text" @click="changeImg(4)">看不清？<span>换张图</span></div>
                    </div>
                </el-form-item>
                <el-form-item label="手机验证码:">
                    <div class="code">
                        <input style="width:563px" v-model="form4.smsCode" type="text">
                        <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                        <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">{{countDown}}</button>
                    </div>
                </el-form-item>
                <el-form-item label="*新密码：">
                    <div class="code">
                        <input style="width:450px" v-model="form4.payPsd" type="text">
                    </div>
                </el-form-item>
                <el-form-item label="*密码确认：">
                    <div class="code">
                        <input style="width:450px" v-model="form4.PsdAgin" type="text">
                    </div>
                </el-form-item>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <el-button class="f-btn" @click="compare(4)">确认</el-button>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import Code from '../code.vue';
export default {
    components: { Code },
    props: {
        qyInfoShow: Boolean,
        qyPasswordShow: Boolean,
        qyRealNameShow: Boolean,
        qyPayPassShow: Boolean,
        info: Object,
        realityInfo: Object
    },
    data() {
        return {
            form1: { //基础信息
                accountKey: this.info.accountKey,
                smsCode: '',
                headPic: this.info.headPic,
            },
            smaCode: '', //验证码
            fileList: [], //回显头像
            form2: { //登录密码
                accountKey: this.info.accountKey,
                accountPhone: this.info.accountPhone,
                accountPsd: '',
                accountPsdAgain: '',
                smsCode: '',
            },
            form3: { //实名认证修改
                smsCode: '',
                province: '',
                city: '',
                industry: '',
            },
            industryList: [
                { id: 'A', title: '农、林、牧、渔业' },
                { id: 'B', title: '采矿业,' },
                { id: 'C', title: '制造业' },
                { id: 'D', title: '电力、热力、燃气及水生产和供应业' },
                { id: 'E', title: '建筑业' },
                { id: 'F', title: '批发和零售业' },
                { id: 'G', title: '交通运输、仓储和邮政业' },
                { id: 'H', title: '住宿和餐饮业' },
                { id: 'I', title: '信息传输、软件和信息技术服务业' },
                { id: 'J', title: '金融业' },
                { id: 'K', title: '房地产业' },
                { id: 'L', title: '租赁和商务服务业' },
                { id: 'M', title: '科学研究和技术服务业' },
                { id: 'N', title: '水利、环境和公共设施管理业' },
                { id: 'O', title: '居民服务、修理和其他服务业' },
                { id: 'P', title: '教育' },
                { id: 'Q', title: '卫生和社会工作' },
                { id: 'R', title: '文化、体育和娱乐业' },
                { id: 'S', title: '公共管理、社会保障和社会组织' },
                { id: 'T', title: '国际组织' },
            ], //行业
            form4: { //交易密码
                smsCode: '',
                payPsd: '',
                PsdAgin: ''
            },
            action: this.$util.upload,
            // 倒计时
            countDownShow: false,
            count: 60,
            countDown: '',
            isdisabledFn: false,
            proList: [], //省
            cityList: [], //市
        };
    },
    watch: {
        // 如果选择了省就调用市接口
        'form3.province'(val) {
            if (val != '') {
                this.getCity()
            }
        }
    },
    mounted() {
        if (this.info.headPic) {
            this.fileList.push({ url: this.info.headPic })
        }
        if (this.qyRealNameShow) {
            this.getPro()
        }
    },

    methods: {
        // 获取省
        getPro() {
            this.$util.post('/address/provinces').then(res => {
                this.proList = res.data
            })
        },
        // 获取所有市
        getCity() {
            this.form3.city = ''
            this.$util.post('/address/city', {
                fid: this.form3.province
            }).then(res => {
                this.cityList = res.data
            })
        },
        handleClose(done) {
            this.$emit('close');
        },
        // 上传
        beforeLoad(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.form1.headPic = imgs.join(',')
        },
        removeImg(file, fileList) {
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.form1.headPic = imgs.join(',')
        },
        // 验证码
        getCode() {
            // const email = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            this.$util.post('sys-sms/sms-send', {
                phone: this.info.accountPhone,
                action: 'Other'
            }).then(res => {
                if (res.code == 200) {

                    this.countDownShow = true
                    this.settime()
                }
            })
        },

        settime() {
            if (this.count == 0) {
                this.count = 60;
                this.countDownShow = false
                return false;
            } else {
                this.countDownShow = true
                this.isdisabledFn = true
                this.countDown = '重新发送' + "(" + this.count + ")"
                this.count--;
            }
            setTimeout(() => {
                this.settime();
            }, 1000);
        },
        changeCode(value) {
            this.checkCode = value;
        },
        // 验证码验证
        compare(num) {
            if (this.smaCode.toUpperCase() === this.checkCode) {
                if (num == 1) {
                    this.$util.post('/userCenter/update/info/login', this.form1).then(res => {
                        if (res.code == 200) {
                            this.$emit('submit')
                        } else {
                            this.count = 0;
                            this.settime()
                            this.$message.error(res.message);
                        }
                    })
                } else if (num == 2) {
                    if (this.form2.accountPsd != this.form2.accountPsdAgain) {
                        return this.$message.error('两次密码不一样!');
                    }
                    this.$util.post('/user/upPsd', this.form2).then(res => {
                        if (res.code == 200) {
                            this.$emit('submit')
                        } else {
                            this.count = 0;
                            this.settime()
                            this.$message.error(res.message);
                        }
                    })
                } else if (num == 3) {
                    this.$util.post('/userCenter/update/user/info', this.form3).then(res => {
                        if (res.code == 200) {
                            this.$emit('submit')
                        } else {
                            this.count = 0;
                            this.settime()
                            this.$message.error(res.message);
                        }
                    })
                } else if (num == 4) {
                    if (this.form4.payPsd != this.form4.PsdAgin) {
                        return this.$message.error('两次密码不一样!');
                    }
                    this.$util.post('/userCenter/update/payPsd', this.form4).then(res => {
                        if (res.code == 200) {
                            this.$emit('submit')
                        } else {
                            this.count = 0;
                            this.settime()
                            this.$message.error(res.message);
                        }
                    })
                }
            } else {
                alert("比对失败,请重新输入");
                this.smaCode = "";
                this.$refs[`ref_validateCode${num}`].draw();
            }
        },
        changeImg(num) {
            this.$refs[`ref_validateCode${num}`].draw();
        }
    },
};
</script>

<style lang="scss" scoped>
.state {
    padding: 20px 0 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
.upload {
    display: flex;
    align-items: center;
    .uload-name {
        font-size: 14px;
        color: #2ea7e0;
        margin-left: 57px;
    }
    /deep/.el-upload--picture-card {
        width: 198px;
        height: 124px;
        background: #fff;
        line-height: 134px;
        margin-right: 20px;
    }
    /deep/.el-upload-list--picture-card .el-upload-list__item {
        width: 198px;
        height: 124px;
    }
}
.upload-item {
    display: flex;
    align-items: center;
    /deep/.el-upload--picture-card {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        background: #fff;
        line-height: 110px;
    }
    /deep/.el-upload-list--picture-card .el-upload-list__item {
        width: 100px;
        height: 100px;
        border-radius: 100%;
    }
}
.code {
    height: 48px;
    // border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    // width: 500px;
    input {
        height: 100%;
        // border: none;
        outline: none;
        padding: 0 15px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #eeeeee;
        margin-right: 10px;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.verification {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .text {
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin-left: 10px;
        span {
            color: #e40012;
        }
    }
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    font-size: 16px;
    cursor: pointer;
    color: #e40012;
    .f-btn {
        padding: 15px 180px;
        background: #e40012;
        color: #fff;
        font-size: 18px;
        margin-left: 40px;
    }
}

.avatar-item {
    margin-left: 25px;
    width: 100px;
    height: 100px;
    background: red;
}
.text {
    font-size: 14px;
    color: #666666;
    cursor: pointer;
    margin-left: 10px;
    span {
        color: #e40012;
    }
}
</style>